<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("teachersList.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button v-b-modal.modal-1>{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("teachersList.inn") }}
                    </th>
                    <th class="text-left">
                      {{ $t("teachersList.firstName") }}
                    </th>
                    <th class="text-left">{{ $t("teachersList.name") }}</th>
                    <th class="text-left">
                      {{ $t("teachersList.parentName") }}
                    </th>
                    <th class="text-left">
                      {{ $t("teachersList.contactPhone") }}
                    </th>
                    <th class="text-left">{{ $t("teachersList.userName") }}</th>
                    <th class="text-left">{{ $t("teachersList.password") }}</th>
                    <th class="text-left">Роль</th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in teachers" :key="item.id">
                    <td>{{ item.inn }}</td>
                    <td>{{ item.firstName }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.parentName }}</td>
                    <td>{{ item.contactPhone }}</td>
                    <td>{{ item.userName }}</td>
                    <td>{{ item.password }}</td>
                    <td>{{ item.role }}</td>
                    <td>
                      <b-button
                        type="submit"
                        @click="editTeacher(item)"
                        variant="primary"
                      >
                        {{ $t("common.edit") }}
                      </b-button>

                      <b-button
                          v-if="item.isDeletable == true"
                          @click="deleteTeacher(item)"
                          variant="danger"
                      >
                        Удалить
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div>
              <b-modal id="modal-1" ref="teacher-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form v-if="show">
                  <b-form-group id="input-group-1" label-for="input-1">
                    <template v-slot:label>
                      {{ $t("teachersList.inn") }}
                    </template>
                    <b-form-input
                        id="input-1"
                        v-model="form.inn"
                        type="email"
                        :state="teacherModelStates.inn"
                        required
                    ></b-form-input>
                    <div class="invalid-feedback">{{teacherModelSaveError.inn}}</div>
                  </b-form-group>
                  <b-form-group id="input-group-1" label-for="input-1">
                    <template v-slot:label>
                      {{ $t("teachersList.firstName") }}
                    </template>
                    <b-form-input
                      id="input-1"
                      v-model="form.firstName"
                      type="email"
                      :state="teacherModelStates.firstName"
                      required
                    ></b-form-input>
                    <div class="invalid-feedback">{{teacherModelSaveError.firstName}}</div>
                  </b-form-group>

                  <b-form-group id="input-group-2" label-for="input-2">
                    <template v-slot:label>
                      {{ $t("teachersList.name") }}
                    </template>
                    <b-form-input
                      id="input-2"
                      v-model="form.name"
                      :state="teacherModelStates.name"
                      required
                    ></b-form-input>
                    <div class="invalid-feedback">{{teacherModelSaveError.name}}</div>
                  </b-form-group>

                  <b-form-group id="input-group-3" label-for="input-3">
                    <template v-slot:label>
                      {{ $t("teachersList.parentName") }}
                    </template>
                    <b-form-input
                      id="input-3"
                      v-model="form.parentName"
                      :state="teacherModelStates.parentName"
                      required
                    ></b-form-input>
                    <div class="invalid-feedback">{{teacherModelSaveError.parentName}}</div>
                  </b-form-group>
                  <b-form-group id="teacherBd-g" label-for="teacherBd">
                    <template v-slot:label>
                      {{ $t("teachersList.birthDate") }}
                    </template>
                    <b-form-datepicker
                        id="teacherBd"
                        v-model="form.birthDate"
                        required
                    ></b-form-datepicker>
                  </b-form-group>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("teachersList.contactPhone") }}
                    </template>
                    <b-form-input
                      id="input-4"
                      v-model="form.contactPhone"
                      :state="teacherModelStates.contactPhone"
                      required
                    ></b-form-input>
                    <div class="invalid-feedback">{{teacherModelSaveError.contactPhone}}</div>
                  </b-form-group>
                  <b-form-group id="input-group-5" label-for="input-5">
                    <template v-slot:label>
                      {{ $t("teachersList.role") }}
                    </template>
                    <b-form-select
                            id="input-2"
                            v-model="form.role"
                            :options="roles"
                            required
                    ></b-form-select>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                    $t("common.save")
                  }}</b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                    $t("common.cancel")
                  }}</b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "teachers",
  components: {
    KTPortlet
  },
  data() {
    return {
      schoolId: null,
      teachers: null,
      dialog: true,
      form: {
        inn: "",
        firstName: "",
        name: "",
        parentName: "",
        contactPhone: "",
        birthDate: ""
      },
      show: true,
      roles: [{text: "Админ", value: 'ROLE_SCHOOL_ADMIN'}, {text: "Преподаватель", value: 'ROLE_TEACHER'}
        , {text: "Мед. сестра", value: 'ROLE_SCHOOL_NURSE'}, {text: "Админ сайта", value: 'ROLE_SCHOOL_SITE_ADMIN'}],
      teacherModelStates:{},
      teacherModelSaveError:{},
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      this.form.schoolId = this.$route.params.id;

      ApiService.postSecured("teachers/save", this.form)
        .then(function() {
          $this.$refs["teacher-modal"].hide();
          $this.reloadData();
          $this.cleanModel();
        })
        .catch(({ response }) => {
          if(response.data != null && response.data.errors != null){
            $this.teacherModelSaveError = response.data.errors;

            for (let i in response.data.errors){

              $this.teacherModelStates[i] = false;
            }
          }
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["teacher-modal"].hide();
    },
    reloadData() {
      ApiService.querySecured("teachers/listSchool", {
        params: { schoolId: this.$route.params.id }
      })
        .then(({ data }) => {
          this.teachers = data.list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    cleanModel() {
      this.form = {};
    },
    editTeacher(teacher) {
      this.form = teacher;
      this.$refs["teacher-modal"].show();
    },
    deleteTeacher(teacher) {
      let $this = this;
      if(confirm("Удалить преподавателя?")){
        ApiService.postSecured("teachers/delete", {id:teacher.id})
            .then(function () {

              $this.reloadData();
            })
            .catch(() => {

            }).finally(function () {

        });
      }
    }
  }
};
</script>
